body {
  background: rgb( 250, 250, 250 );
}

.tile {
  min-width: 300px;
  min-height: 300px;
  //max-width: 300px;
  flex-grow: 1;
  flex-basis: 0;
}

.flexSection.gap5pct {
  gap: 5%;
}

.flexSectionColumn.gap5pct {
  row-gap: 20px;
}

.flexSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexSectionColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flexTile {

}

//----------------------------------------
// Flex
//----------------------------------------
.flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.flex-row-reverse {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.flex-col,
.flex-column {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flex-col-reverse,
.flex-column-reverse {
	display: Flex;
	flex-direction: column-reverse;
	align-items: center;
}

//----------------------------------------
// Margins
//----------------------------------------
@for $i from 1 through 10 {
	.margin-#{$i * 5} {
		margin: 5px * $i;
	}

	.margin-top-#{$i * 5} {
		margin-top: 5px * $i;
	}

	.margin-bottom-#{$i * 5} {
		margin-bottom: 5px * $i;
	}

	.margin-right-#{$i * 5} {
		margin-right: 5px * $i;
	}

	.margin-left-#{$i * 5} {
		margin-left: 5px * $i;
	}
}

//----------------------------------------
// Padding
//----------------------------------------
@for $i from 1 through 10 {
	.padding-#{$i * 5} {
		padding: 5px * $i;
	}

	.padding-top-#{$i * 5} {
		padding-top: 5px * $i;
	}

	.padding-bottom-#{$i * 5} {
		padding-bottom: 5px * $i;
	}

	.padding-right-#{$i * 5} {
		padding-right: 5px * $i;
	}

	.padding-left-#{$i * 5} {
		padding-left: 5px * $i;
	}
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// To generate custom palette: http://mcg.mbitson.com/
$nxthealth-dashboard-primary-palette: (
    50 : #e0f0ff,
    100 : #b3d9ff,
    200 : #80c0ff,
    300 : #4da6ff,
    400 : #2693ff,
    500 : #0080ff,
    600 : #0078ff,
    700 : #006dff,
    800 : #0063ff,
    900 : #0050ff,
    A100 : #ffffff,
    A200 : #f2f5ff,
    A400 : #bfcfff,
    A700 : #a6bcff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$nxthealth-dashboard-primary: mat.define-palette($nxthealth-dashboard-primary-palette);

$nxthealth-dashboard-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$nxthealth-dashboard-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nxthealth-dashboard-theme: mat.define-light-theme((
  color: (
    primary: $nxthealth-dashboard-primary,
    accent: $nxthealth-dashboard-accent,
    warn: $nxthealth-dashboard-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nxthealth-dashboard-theme);

// Adding this here to extract the scss variables into web variables to avoid the angular budget warning
$palette-attrs: "default", "lighter", "darker";
:root {
  @each $key, $palette in map-get($nxthealth-dashboard-theme, "color") {
    @if $palette {
      @each $palette-attr in $palette-attrs {
        --color-#{$key}-#{$palette-attr}: #{map-get($palette, $palette-attr)};
        --color-#{$key}-#{$palette-attr}-contrast: #{map-get($palette, "#{$palette-attr}-contrast")};
      }
    }
  }
}
/* You can add global styles to this file, and also import other style files */

@import "theme";
@import "display";
@import "typography";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



.mat-mdc-card-header {
    font-weight: bold;
    font-size: large;
    text-align: center;
}

.xng-breadcrumb-root {
    padding: 8px 16px;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
  }

.xng-breadcrumb-trail {
    font-weight: bold;
    color: #0000EE;
    text-decoration: underline;
}

//.spinner-div {
//  margin: 0 auto;
//}

.center.wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.center .inner {
  position: absolute;
  inset: 0;
  margin: auto;
}
/* +++++++ STYLES +++++++ */
.wrapper {
  height: calc(100vh - 20px);
}
.inner {
  padding: 12px;
  height: max-content;
  width: max-content;
}

.mat-tooltip-no-max-width .mdc-tooltip__surface {
  max-width: unset;
}






.form-input-column > mat-form-field {
  width: 100%;
  margin-bottom: 5px;
}


.form-checkbox-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.checkbox-item {
  margin: 0 10px;
}

.form-input-column > .form-checkbox-row {
  width: 100%;
}


.form-input-row {
  display: flex;
  flex-wrap: nowrap;
}

.form-input-column {
  flex: 1;
  padding: 10px;
}
